import React from "react";
import * as styles from "./BtnClose.module.scss";

const BtnClose = ({ onClick }) => {
  return (
    <button onClick={() => onClick()} className={styles["btn_close"]}>
      <span></span>
    </button>
  );
};

export default BtnClose;
