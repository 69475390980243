import * as Yup from "yup";

export const validation = Yup.object().shape({
  candidateName: Yup.string().required("Please enter your name"),
  candidateEmail: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
  candidateMessage: Yup.string().required("Please enter your message"),
  // candidateFile: Yup.string().required("Please upload your resume"),
});

export const initialValues = {
  candidateName: "",
  candidateEmail: "",
  candidatePosition: "",
  candidateMessage: "",
  candidateFile: "",
  candidateFileName: "",
};
