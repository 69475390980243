import React, { useState, useEffect } from "react";
import * as styles from "./CareerForm.module.scss";
import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import classNames from "classnames";
import {
  loadRecaptcha,
  removeRecaptcha,
  getRecaptchaToken,
} from "../../utils/googleRecaptcha";
import InputField from "../contact/InputField";
import useFileUpload from "../contact/useFileUpload";
import { initialValues, validation } from "../../forms/formCareer";
import upload from "../../images/upload.svg";
import BtnBack from "../common/BtnBack";

const CareerForm = ({ position, onClose }) => {
  const [initVal, setInitVal] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [responseMsg, setResponseMsg] = useState();
  const [formLoaded, setFormLoaded] = useState(false);
  const { file, fileName, handleUpload, error: uploadError } = useFileUpload();

  useEffect(() => {
    loadRecaptcha();
    return () => removeRecaptcha();
  }, []);

  useEffect(() => {
    setFormLoaded(true);
    return () => setFormLoaded(false);
  }, []);

  useEffect(() => {
    position &&
      setInitVal({
        ...initVal,
        candidatePosition: position,
      });
  }, [position]);

  async function handleSubmit(values) {
    if (!file || !fileName)
      return setResponseMsg({ error: "Please upload your resume" });
    else setResponseMsg();
    setLoading(true);
    try {
      const recaptchaCode = await getRecaptchaToken("career_form");
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: {
          ...values,
          candidateFile: file,
          candidateFileName: fileName,
          candidatePosition: position,
          token: recaptchaCode,
        },

        url: "/.netlify/functions/career-submit",
        withCredentials: false,
      };
      console.log(values);
      const response = await axios(options);
      console.log("response", response);
      setResponseMsg({
        success:
          "Your email has been sent. We will get back to you in one working day.",
      });
      return true;
    } catch (err) {
      console.log("err", err);
      let errorMsg = "Error occurred. Pease try again";

      if (err.response) {
        console.log("err response", err.response);
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMsg = err.response.data.message;
      } else if (err.request) {
        console.log("err request", err.request);
        // The request was made but no response was received
        // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errorMsg = err.request;
      } else {
        console.log("err message", err.message);
        // Something happened in setting up the request that triggered an Error
        errorMsg = err.message;
      }
      console.log("errorMsg", errorMsg);
      setResponseMsg({ error: errorMsg });
    }
  }

  function handleClose() {
    setFormLoaded(false);
    setTimeout(() => {
      onClose();
    }, 400);
  }

  return (
    <>
      <div
        className={classNames(
          styles["btn_back"],
          formLoaded && styles["active"]
        )}
      >
        <BtnBack onClick={() => handleClose()} />
      </div>
      <div
        className={classNames(
          styles["overlay"],
          formLoaded && styles["active"]
        )}
      >
        <div className={styles["container"]}>
          <div className="wrapper">
            <Formik
              initialValues={initVal}
              validationSchema={validation}
              validateOnChange={false}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const result = await handleSubmit(values);
                if (result) {
                  setSubmitting(false);
                  setLoading(false);
                  resetForm();
                }
              }}
            >
              {({ status, handleChange, setFieldValue }) => (
                <Form encType="multipart/form-data" className={styles["form"]}>
                  {position && <h3 className="h3">Apply for {position}</h3>}
                  <InputField
                    id="candidateName"
                    inputName="candidateName"
                    placeholder="John Smith"
                    label="Name"
                  />
                  <InputField
                    id="candidateEmail"
                    inputName="candidateEmail"
                    placeholder="johnsmith@example.com"
                    type="email"
                    label="Email"
                  />
                  <InputField
                    component="textarea"
                    id="candidateMessage"
                    inputName="candidateMessage"
                    placeholder="Your message here..."
                    label="Message"
                  />
                  <div className={styles["file_upload"]}>
                    <input
                      id="candidateFile"
                      name="candidateFile"
                      type="file"
                      onChange={handleUpload}
                      className="form-control"
                      accept="application/pdf, application/msword, image/png, image/jpeg"
                    />

                    <label htmlFor="candidateFile">
                      <img src={upload} alt="" />
                      {!fileName
                        ? "Upload your resume"
                        : `Resume (${fileName})`}
                    </label>
                    <ErrorMessage
                      className="msg-error"
                      component="div"
                      name="candidateFile"
                    />
                    {uploadError && <p className="msg-error">{uploadError}</p>}
                  </div>
                  {status && status.message && (
                    <div
                      className={`alert ${
                        status.success ? "alert-success" : "alert-error"
                      }`}
                    >
                      {status.message}
                    </div>
                  )}
                  {responseMsg && (
                    <p className="response_msg">
                      {responseMsg.error && (
                        <span className="error_msg">{responseMsg.error}</span>
                      )}
                      {responseMsg.success && (
                        <span className="success_msg">
                          {responseMsg.success}
                        </span>
                      )}
                    </p>
                  )}
                  <button type="submit" className="btn" disabled={loading}>
                    Submit
                  </button>
                  {loading ? <div className="loading"></div> : ""}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerForm;
