import React from "react";
import * as styles from "./PositionDetails.module.scss";

const PositionDetails = ({ data, onApply }) => {
  return (
    <div className={styles["container"]}>
      <div className="wrapper">
        <h2 className="h2">{data.name}</h2>
        {data.responsibilities && (
          <>
            <h3 className="h4">Responsibilities</h3>
            <ul className="custom-list">
              {data.responsibilities.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          </>
        )}
        {data.requirements && (
          <>
            <h3 className="h4">Requirements</h3>
            <ul className="custom-list">
              {data.requirements.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          </>
        )}
        <button onClick={() => onApply()} className="btn">
          Apply Now
        </button>
      </div>
    </div>
  );
};

export default PositionDetails;
