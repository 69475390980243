import React from "react";
import * as styles from "./BtnBack.module.scss";

const BtnBack = ({ onClick }) => {
  return (
    <button
      onClick={() => onClick()}
      className={styles["btn_back"]}
      aria-label="back button"
    >
      <i className={styles["arrow_back"]}></i>
    </button>
  );
};

export default BtnBack;
