import { useState } from "react";

const useFileUpload = () => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [error, setError] = useState(null);

  const handleUpload = async (event) => {
    const file = event.target.files && event.target.files[0];
    setError(null);
    if (
      file &&
      checkMimeType(file) &&
      checkFileSize(file)
      // checkFileDimension(file)
    ) {
      //   console.log("data", file);
      //   const reader = new FileReader();
      //   reader.readAsDataURL(file);
      //   reader.onload = function (e) {
      //     let image = new Image();
      //     image.src = e.target.result;
      //     image.onload = function () {
      //       setImage(reader.result);
      //     };
      //   };
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setFileName(file.name);
        setFile(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  };

  const checkMimeType = (file) => {
    // Allowed mime type
    const types = [
      "application/pdf",
      "application/msword",
      "image/png",
      "image/jpeg",
    ];
    if (types.every((type) => file.type !== type)) {
      setError(
        "File type not supported. Accept .pdf .doc, .jpg and .png only."
      );
      return false;
    }
    return true;
  };
  const checkFileSize = (file) => {
    const maxSize = 2 * 1000000; // 2mb
    const minSize = 2 * 1000; // 2kb
    console.log("size -------", file.size);
    if (file.size > maxSize) {
      setError(`File size is too big. Max file size 2MB.`);
      return false;
    } else if (file.size < minSize) {
      setError(`File size is too small. Min file size 2KB.`);
      return false;
    }
    return true;
  };

  return { file, fileName, error, handleUpload };
};

export default useFileUpload;
