import React, { useState } from "react";
import Modal from "react-modal";
import { Row, Col } from "../layout/Grid";
import ScrollReveal from "../layout/ScrollReveal";
import ModalPosition from "./ModalPosition";
import * as styles from "./OpenPosition.module.scss";

Modal.setAppElement("#___gatsby");

const careers = [
  {
    name: "Web & App Developer",
    desc: "Someone who writes high-quality, clean, maintainable and performance-driven codes",
    responsibilities: [
      "Develop functional and appealing web and mobile-based applications based on usability",
      "Be involved in establishing new ideas for web and mobile solutions",
      "Able to do entire development life cycle (concept, design, build, deploy, test and support",
      "Working with the designer to build the interface with focus on usability features",
      "Develop code, maintain existing code bases, troubleshooting, debugging and testing",
      "Write high-quality, clean, maintainable and performance driven codes",
    ],
    requirements: [
      "Hands-on experience in React, ReactNative, Firebase or NodeJs",
      "Working knowledge of PHP, MySQL, WordPress, and other CMS development",
      "Must have strong skills in HTML, JavaScript, CSS and JS Frameworks",
      "Strong understanding of RESTful APIs",
      "Familiar with Xcode, iOS SDK and Android SDK",
      "Ability to stay flexible in day-to-day work and meet deadlines",
    ],
  },
  {
    name: "UI & UX Designer",
    desc: "A person who loves all things design for humans and can process UX from concept to outcome through overview discussions to the finer details",
    responsibilities: [
      "Create creative design that are user-friendly, effective and appealing",
      "Create concepts, wireframes, and designs that deliver compelling user experience, drive user behaviour and communicate key ideas.",
      "Liaise with Web Developers and Project Coordinator to drive web development initiatives",
      "Working with a variety of design and optimisation tools",
    ],
    requirements: [
      "Strong Visual design talent and experience for different digital mediums (web, mobile and others)",
      "Proficient with design and wireframing/prototyping tools such as Illustrator, Photoshop and others",
      "Ability to both criticize and offer convincing alternatives to existing designs",
      "Ability to work collaboratively with design, development, and marketing management",
      "Broad knowledge of the web industry and software development project lifecycles",
    ],
  },
  {
    name: "Business Development Executive/Manager",
    desc: "You are the primary ownership of Lead Generation and Sales for the company",
    responsibilities: [
      "Develop sales leads, sales strategies and plans, and close sales",
      "Grow client accounts through identification of new opportunities and strengthened client relationships",
      "Manage the client relationship, acting as client advocate and voice through our project lifecycle",
      "Demonstrating an entrepreneurial spirit and attitude",
      "Stay in tune with market trends and demands to adapt our sales approach proactively and effectively",
      "To identify new areas for growth",
    ],
    requirements: [
      "Knowledge of digital marketing and/or digital creative",
      "Any IT technical knowledge would be advantage",
      "Strong communication, human, negotiation and influencing skills to drive sales",
      "Excellent command of spoken and written English and Chinese",
    ],
  },
  {
    name: "Digital Project Executive",
    desc: "A go-getter who can jump into a new task to quickly understand and define the scope of work, write the requirements, and follow up subsequently.",
    responsibilities: [
      "Conceptualise and assist in managing digital marketing/web solutions for client needs",
      "Preparing of project requirements, UAT and any relevant documentations",
      "Provide day-to-day monitoring to assigned project(s), to ensure timely and accurate project execution",
      "Generate new ideas that would meet client's needs",
      "Work closely with production team to ensure the project progress smoothly",
      "Troubleshoot the problems and take necessary actions and reporting",
      "Assist in new project pitches",
    ],
    requirements: [
      "Knowledge of digital marketing and/or digital creative",
      "Any IT technical knowledge would be advantage",
      "Experience in digital strategy, marketing, communication or other pertinent knowledge",
      "Fluency in both Chinese and English, spoken and written, is mandatory",
    ]
  },
  {
    name: "Internship for Computer/IT Students",
    desc: "Hardworking individuals who are enthusiastic about assisting on design and development projects",
    responsibilities: [
      "Design and develop website",
      "Maintain website to ensure all contents are accurate and up-to-date",
      "Design and develop e-newsletter",
      "Working with a variety of design and optimisation tools",
      "Keeping up with the latest design software and technologies",
    ],
  },
];

const OpenPosition = () => {
  const [selectedData, setSelectedData] = useState(false);
  return (
    <>
      <section className="section-light">
        <div className="section wrapper">
          <div className={`title ${styles.title}`}>Open Position</div>
          <Row>
            <Col width="20%">
              <ScrollReveal effect="slide-left" delay={50}>
                <h2 className="h2">Open Position</h2>
              </ScrollReveal>
            </Col>
            <Col width="70%">
              <ul className={styles["list"]}>
                {careers.map((item, index) => {
                  return (
                    <li key={index}>
                      <ScrollReveal
                        effect="slide-right"
                        delay={index * 100 + 50}
                      >
                        <button
                          className={styles["btn"]}
                          onClick={() => setSelectedData(item)}
                        >
                          <h3 className="h3">{item.name}</h3>
                          <p>{item.desc}</p>
                        </button>
                      </ScrollReveal>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </div>
      </section>
      {selectedData && (
        <ModalPosition
          data={selectedData}
          onClose={() => setSelectedData(false)}
        />
      )}
    </>
  );
};

export default OpenPosition;
