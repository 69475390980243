import classNames from "classnames";
import React, { useState } from "react";
import Modal from "react-modal";
import * as styles from "./ModalPosition.module.scss";
import CareerForm from "./CareerForm";
import PositionDetails from "./PositionDetails";
import BtnClose from "../common/BtnClose";

const ModalPosition = ({ data, onClose }) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [modalOpened, setModalOpened] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  return (
    <Modal
      closeTimeoutMS={1250}
      isOpen={modalOpen}
      onAfterOpen={() => setModalOpened(true)}
      onAfterClose={() => onClose()}
      className={classNames(
        styles["modal"],
        modalOpened && styles["modal_opened"],
        formOpen && styles["form_open"]
      )}
      style={{ overlay: { background: "transparent" } }}
    >
      <div className={styles["background"]}></div>
      <div className={styles["btn_close"]}>
        <BtnClose
          onClick={() => {
            setModalOpened(false);
            setModalOpen(false);
            setTimeout(() => {
              setFormOpen(false);
            }, 300);
          }}
        />
      </div>
      <div className={styles["container"]}>
        <PositionDetails data={data} onApply={() => setFormOpen(true)} />
        {formOpen && (
          <CareerForm
            position={data.name}
            onClose={() => {
              setTimeout(() => {
                setFormOpen(false);
              }, 300);
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default ModalPosition;
